<template>
  <div class="account-patient-data-wrapper">
    <div class="col-data" v-for="col in cols" :key="col.key">
      <div class="label">{{ col.label }}</div>
      <div class="props">
        {{ getFormattedProps(col.key) }}
        <WhatsAppIcon
          class="whatsapp"
          v-if="col.key === 'cellphone' && getFormattedProps(col.key) !== '-'"
          @click="openWhatsApp(getFormattedProps(col.key))"
        />
      </div>
    </div>
  </div>
</template>
<script>
import WhatsAppIcon from '@/assets/icons/whatsapp-icon.svg'

export default {
  components: { WhatsAppIcon },
  props: {
    patient: Object,
  },
  data() {
    return {
      cols: [
        { label: 'Paciente', key: 'name' },
        { label: 'Nascimento', key: 'birthday' },
        { label: 'CPF', key: 'cpf' },
        { label: 'E-mail', key: 'email' },
        { label: 'Celular', key: 'cellphone' },
      ]
    }
  },
  methods: {
    getFormattedProps(key) {
      const props = {
        'name': () => this.patient?.name || '-',
        'birthday': () => this.getBirthdayAndYear(this.patient?.birthday) || '-',
        'cpf': () => this.patient?.cpf || '-',
        'email': () => this.patient?.email || '-',
        'cellphone': () => this.patient?.cellphone || '-',
        'default': () => '-',
      }
      return (props[key] || props.default)()
    },
    getBirthdayAndYear(birthday) {
      if (!birthday) return
      return `${this.moment(birthday).format('DD/MM/YYYY')} (${this.moment().diff(this.moment(this.patient.birthday), 'years')} anos)`
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')
      if(whatsappNumber.length < 10){
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
        window.open(`https://api.whatsapp.com/send?phone=55${whatsappNumber}`, '_blank')
    },
  }
}
</script>
<style lang="scss" scoped>
.account-patient-data-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 0 0 1rem;
  border-bottom: 1px solid var(--neutral-200);

  margin-bottom: 10px;
  .col-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 130px;
  }
  .label {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-blue);
  }
  .props {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: var(--type-active);
  }
  .whatsapp {
    cursor: pointer;
  }

  @media(max-width: 2000px) {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
</style>
